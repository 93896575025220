import React, { useContext } from "react";
import styled from "styled-components";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import AppContext from "Contexts/AppContext";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import { getAllNotifications, NotificationRedirect } from "common/utils/helper";
import UserContext from "Contexts/User";
import Button, { Buttons } from "Components/Atoms/Button/Button";
import ShareModal from "Components/shareModal";

type NotificationProps = {
  date?: Date;
  title: string;
  body: string;
  notificationData: any;
  limitValue:any;
}

const BigDiv = styled.div`
  width: 100%;
  max-width: 360px;
  height: auto;
  background: var(--white) 0 0% no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;  
  flex-direction: column;
  padding: 15px 10px 15px 15px;
  margin: 0 auto 1px;
  cursor: pointer;
`;
const Container = styled.div`
  width: 100%;
  max-width: 360px;
  height: auto;
  // background: var(--white) 0 0% no-repeat padding-box;
  // box-shadow: 0 3px 6px #00000029;
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 15px 10px 15px 15px;
  margin: 0 auto 1px;
  cursor: pointer;
`;
const Date = styled.div`
  font: var(--font-style-normal) normal var(--font-weight-normal) var(--font-size-12)/var(--line-spacing-10) var(--font-family-poppins);
  font-size: var(--font-size-12);
  letter-spacing: var(--character-spacing-0);
  line-height: var(--line-spacing-10);
  color: var(--color-868686);
  text-align: left;
  opacity: 1;
  width:96px;
  line-height: 16px;  
`;

const Msg = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width:204px;
  line-height: var(--line-spacing-10);
  >div>* {
    display: inline;
  }
`;

const Title = styled.div`
  font: var(--font-style-normal) normal var(--font-weight-normal) var(--font-size-12)/var(--line-spacing-14) var(--font-family-poppins);
  font-size: var(--font-size-12);
  letter-spacing: var(--character-spacing-0);
  line-height: var(--line-spacing-10);
  color: var(---160133);
  text-align: left;
  opacity: 1;  
  margin-right: 2px;
  line-height: 16px;  
`;

const Body = styled.div`
  font: var(--font-style-normal) normal var(--font-weight-normal) var(--font-size-12)/var(--line-spacing-14) var(--font-family-poppins);
  font-size: var(--font-size-12);
  line-height: var(--line-spacing-10);
  letter-spacing: var(--character-spacing-0);
  color: var(---160133);
  text-align: left;
  opacity: 1;
  line-height: 16px;  
`;

const Notification = ({ date, title, body,notificationData,limitValue }: NotificationProps) => {
  const navigate = useNavigate();
  const { setTradeHistoryShow, setTradeHistoryTab,setOpenOfferTab} = useContext(AppContext);
  const { notifications, setNotifications, userInfo } = useContext(UserContext)
  const [shareModalShow, setShareModalShow] = React.useState(false);
  const updateNotificationSeenForCardTrade = httpsCallable(functions, "updateNotificationSeenForCardTrade")  

  // const handleClick = () => {
  //   if(title.includes("Mining Pass Activated")|| title.includes("Mining Pass Expired")){
  //     navigate('/upgrade');
  //   }
  //   if(title.includes("Golden Vote Now Available")){
  //     // navigate('/spin');
  //   }
  //   if (title === "Congratulations! Your Card Purchase is Complete!") {
  //     navigate('/profile/trade'); 
  //     setTradeHistoryShow('TradeHistory')
  //   }
    
  //   if (title === "Congratulations! Your Card Deal is Complete!") {
  //     navigate('/profile/trade'); 
  //     setTradeHistoryShow('TradeHistory')
  //     setTradeHistoryTab(1)
  //   }

  //   if (title === "Congratulations! You've Received a Gift!" || title === "Congratulations! Your Gift Has Been Approved!") {
  //     navigate('/profile/trade'); 
  //     setTradeHistoryShow('TradeHistory')
  //     setTradeHistoryTab(2)
  //   }

  //   if (title === "Trade Negotiation Alert!") {
  //     navigate(`/profile/trade?tabID=3`);
  //   }
  // };

  const UnseenNotifaction =(notificationId:string,limitValue:number)=>{
    const data = {
      notificationId
    }    
    updateNotificationSeenForCardTrade(data).then((result) => {            
          getAllNotifications(userInfo?.uid, setNotifications, limitValue)      
      console.log(result)
    }).catch((err) => {
      console.log(err)
    });
  }

  
    const getHighlightedText = (text: string) => {
      const coinList=["PEPE-FLOKI","ETH-BNB","XRP-ADA","BTC-ETH",
        "DOGE-SHIB","SAND-MANA","DOT-MATIC","UNI-CAKE","LTC-XRP","LINK-TRX","ADA-SOL","CRO-BNB","BTC","PEPE","FLOKI","ETH","BNB","ADA","SOL","XRP","DOGE","DOT","SHIB","MATIC","CRO","LTC",
        "LINK","UNI","TRX","XLM","MANA","HBAR","VET","SAND","EOS","CAKE","TRUMP"
    ] 
      let regex = new RegExp(`\\b(${coinList.join("|")})\\b`, "gi");
  
      return text.split(regex).map((part, index) => {
        if (coinList.includes(part)) {
          return (
            <span key={index} style={{ color: "#6352e8", fontWeight: "bold" }}>
              {part}
            </span>
          );
        }
        return part;
      });
    };

  const url = `${document.location.protocol}//${document.location.host}`
  const shareText = "Track my journey! Join the Parliament and grow alongside me."  

  return (
    <BigDiv className="mt-2"    
    >    
    <Container className=""
    onClick={()=>{
      if(!notificationData.notificationSeen){
        UnseenNotifaction(notificationData?.notificationId,limitValue)
      }      
    }}
    >
      <Date
      style={{
        fontWeight: `${notificationData?.notificationSeen?"200":"500"}`,
        color:`${notificationData?.notificationSeen?"#868686":"black"}`
      }}
      >{date && moment(date).format("HH:mm DD.MM.YYYY")}</Date>
      <Msg onClick={()=>{
        NotificationRedirect(notificationData,setTradeHistoryShow,setTradeHistoryTab,navigate)
      }}>
        <div>
          <Title 
          style={{
            fontWeight: `${notificationData?.notificationSeen?"200":"500"}`,            
          }}
          >{title.replace("MATIC", "POL")}</Title>
          <br />
          <br />
          <Body>{
            title.includes("Golden Vote: Limited Time!") || title.includes("Golden Vote Now Available!")?
          getHighlightedText(body):body
          }</Body>
        </div>        
        {!notificationData?.notificationSeen && <div style={{          
          marginLeft: "5px",          
        }}>   
        🔴       
        </div>}
      </Msg>      
    </Container>
    {title.includes("🔥 You Were On Fire! 🔥") || title.includes("You were In The Zone!")?
    <div>
      <button
      style={{
        backgroundColor:"#6352e8",
        color:"white",
        padding:"10px 10px",
        borderRadius:"5px",
        border:"none",
        cursor:"pointer",
        fontSize:"12px",
        fontWeight:"500",
        lineHeight:"16px",
        letterSpacing:"0px",
        textAlign:"center",        
        
      }}
      onClick={()=>{
        setShareModalShow(true)
      }}
      >
          Share the glory
      </button>
      </div>
      :null}

    {shareModalShow && <ShareModal shareModalShow={shareModalShow} setShareModalShow={setShareModalShow} url={url} shareText={shareText} hedingText="Share your progress"/>}
    </BigDiv>
  );
};

export default Notification;

