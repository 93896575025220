import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from 'styled-components';


export type SliderItems = {

};

const SliderDiv = styled.div`
  // padding: 20px;
  padding: ${window.screen.width > 767 ?"0px 0px 0px 25px":""};
  
  .slick-slider {
    
  .slick-list {
    .slick-track {
      width: ${window.screen.width > 767 ?"100%":""} !important;
      display: ${window.screen.width > 767 ?"flex":""};
      justify-content:${window.screen.width > 767 ?"space-between":""};
    }    
    }
  }
`;

function SwiperBar({ children, slideSize = 5 }: { children: React.ReactNode | string, slideSize?: number }) {
  
  
  var settings = {
    dots: true,
    infinite: false,
    centerMode: true,
    speed: 500,
    slidesToShow: slideSize,
    slidesToScroll: slideSize,
    initialSlide: 0,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 2160,
        settings: {
          slidesToShow: 4,
          // slidesToScroll: 4,
          // infinite: true,
          
          // dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          
          dots: true
        }
      },
      {
        breakpoint: 850,
        settings: {
          centerMode: true,
          // @ts-ignore
          slidesToShow: children?.length == 1 ? 1 :2,
          // @ts-ignore
          slidesToScroll: children?.length == 1 ? 1 :2,
          // @ts-ignore
          initialSlide: children?.length == 1 ? 1 :2,
          infinite: true,
        }
      },
      {
        breakpoint: 450,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 390,
        settings: {
          centerMode: true, // Enable center mode for slight visibility
          centerPadding: "10%", // Show 2% of the side cards
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
        },
      },
    ]
  };

  console.log(children,"children")
  return (
    <SliderDiv 
      style={{ overflow: "hidden" }}
    >
      {/* @ts-ignore */}
      <Slider {...settings} className='w-100'>
        {/* @ts-ignore */}
        {children}
      </Slider>
    </SliderDiv>
  );
}

export default SwiperBar;