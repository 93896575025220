import axios from 'axios';
import { setChecked } from 'common/models/User';
import Leaderboard from 'Components/Leaderboard';
import { texts } from 'Components/LoginComponent/texts'
import CoinsContext from 'Contexts/CoinsContext';
import UserContext from 'Contexts/User';
// import { functions } from 'firebase';
import { functions } from "../../firebase";
import { httpsCallable } from 'firebase/functions';
import React, { useContext, useEffect, useState } from 'react'
import { showToast } from 'App';
import { ToastType } from 'Contexts/Notification';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'common/models/Dictionary';
import { getUserData } from 'common/utils/helper';
import AppContext from 'Contexts/AppContext';



function ShowBuyList({ activeTab, setActiveTab ,TotalCard, singalCardData}: {activeTab:any, setActiveTab:any ,TotalCard?:number,singalCardData?:any}) {
    const { user, userInfo } = useContext(UserContext);
    const { leaders } = useContext(CoinsContext);
    const {setSeletcardHolderType} = useContext(AppContext);
    const [isLoadingText, setIsLoadingText] = useState<any>(false);
    const [allBuyerList, setAllBuyerList] = useState<any>([]);    
    const [isLoading, setIsLoading] = useState<any>(false);    
    const [approvedCard, setApprovedCard] = useState<any>();
    const [followersDetails, setFollowersDetails] = useState<any>();
    const [buyerCards, setBuyerCard] = useState<any>([]);
    const [coinModelShow, setCoinModelShow] = useState<any>(false);
    const getList = httpsCallable(functions, `cardHolderListing`);


    useEffect(() => {  
        setIsLoadingText(true);
        getRequrstList("BID");
        setSeletcardHolderType("Buyers");
    }, [userInfo?.uid, activeTab])
    

    let params = useParams();
    const translate = useTranslation();
    const { type, id } = params;
    const navigate = useNavigate();


    const getFollwersList = async (id: any) => {
        setIsLoadingText(true);
        const initiated: any = (await getListByUserId()) || [];

        await getList({ cardId: id })
            .then(async (list) => {
                // @ts-ignore
                const FollowerList = list?.data?.map((items: any) => {
                    let arrayData: any = initiated.filter(
                        (e: any) =>
                            e.sellerUserId == items.uid &&
                            e.cardId === id &&
                            !["REJECTED", "APPROVED", "EXPIRED"].includes(e.status)
                    );
                    return {
                        leaders: items?.leader?.length || 0,
                        displayName: items?.displayName,
                        userId: items?.uid,
                        avatar: items?.avatar,
                        status: items?.status?.name,
                        phone: items?.phone,
                        country: items?.country,
                        score: items?.voteStatistics?.score,
                        totalVote: items?.voteStatistics?.total,
                        successful: items?.voteStatistics?.successful,
                        subscribers: items?.subscribers.length || 0,
                        pct:
                            items?.voteStatistics?.successful / items?.voteStatistics?.total,
                        firstName: items?.firstName,
                        email: items?.email,
                        lastName: items?.lastName,
                        isUserUpgraded: items?.isUserUpgraded,
                        userName: items?.userName,
                        tradeCardData: arrayData[0],
                        miningPass:items?.miningPass,
                    };
                });
                setFollowersDetails(FollowerList);
                setIsLoadingText(false);
            })
            .catch((error) => {
                console.log(`error: ${JSON.stringify(error)}`);
                setIsLoadingText(false);
            });
    };
    const getListByUserId = async () => {
        // setFollowerListByUserd
        let responce: any = await axios
            .get(`cardTrading/getInitiatedTrade/${user?.uid}`)
            .then((res) => {
                if (res.status === 200) {
                    setBuyerCard(res?.data?.result);
                    return res?.data?.result;
                }
            })
            .catch((error) => {
                return [];
            });

        return responce;
    };

    const getRequrstList = async (type: string) => {
        try {
            axios
                .get(`/cardTrading/getUserForTradeAccount/${id}/${type}`)
                .then((response) => {
                    if (response.status === 200) {
                        fetchData(response?.data?.result, type);
                    }
                })
                .catch((err) => {
                    showToast(err.response.data.message, ToastType.ERROR);
                });
        } catch (error) { }
    };


    async function fetchData(allList: any, type: any) {
        let allDataList: any = [];
        const promises = allList?.map(async (item: any) => {
            const getUserDetelist = await getUserData(
                type == "BID" ? item?.buyerUserId : item?.sellerUserId
            );
            return {
                buyerData: { ...item },
                ...getUserDetelist,
                status: getUserDetelist?.status?.name,
                leaders: getUserDetelist?.leader?.length || 0,
                subscribers: getUserDetelist?.subscribers?.length || 0,
            };
        });
        allDataList = await Promise.all(promises);
        if (type == "BID") {
            setAllBuyerList(allDataList);
            setIsLoadingText(false);
        }        
    }


    console.log(allBuyerList,"allBuyerList")
    console.log(TotalCard,"TotalCardTotalCard")

  return (
      <div>          
              <div>
                  {allBuyerList?.length > 0 ? (
                    <>
                    {TotalCard && TotalCard < 2 && <p className="text-center mb-2"
                  style={{color:"#0e0e0e"}}
                  >
                          You have only one card, so you can't approve or make a purchase request.       
                        </p>}
                      <Leaderboard
                          {...{
                              leaders: allBuyerList,
                              userInfo,
                              setChecked: setChecked(leaders, user, setIsLoading),
                              singalCardData:singalCardData,
                              buyerCards,
                              getFollwersList,
                              setApprovedCard,
                              setCoinModelShow,
                          }}
                      />
                    </>
                  ) : (
                      <>
                          {isLoadingText == true ? (
                              <div className="d-flex justify-content-center">
                                  <p
                                      className="loading text-center mb-2"
                                      style={{
                                          color: "#6352e8",
                                          // top: `${window.scrollY + 300}px`,
                                      }}
                                  >
                                      {texts?.waitForIt}
                                  </p>
                              </div>
                          ) : (
                                  <p className="text-center mb-2"
                                      style={{
                                          color: "#000",                                          
                                      }}
                                  >
                                  Not Found Any Buyer
                              </p>
                          )}
                      </>
                  )}
              </div>
    </div>
  )
}

export default ShowBuyList