//@ts-nocheck
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// Animation
import { easeQuadIn,easeCubicInOut } from "d3-ease";
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import { useWindowSize } from "../../hooks/useWindowSize";
import UserContext from "Contexts/User";
import {
  CurrentCMPContext,
  CurrentCMPDispatchContext,
} from "Contexts/CurrentCMP";
import AppContext from "Contexts/AppContext";
const CircularProgress = ({ percentage, claimCountFun,animationStop ,setAnimationStop,handleCmpPopupShow}) => {
  const { width: w = 0 } = useWindowSize();
  const [startValue, setStartValue] = useState(false);
  const { user, userInfo } = useContext(UserContext);
  // const [ExtraValue, setExtraValue] = useState(0);  
  const { prevCMP,lastCpmMini, latestCMP, setHeaderCpmStart } = useContext(AppContext);
  const [endValue, setEndValue] = useState<Number>(
    userInfo?.voteStatistics?.score % 100 || 0
  );
  const currentCMP = useContext(CurrentCMPContext);
  const setCurrentCMP = useContext(CurrentCMPDispatchContext);
  const ExtraValue = useRef(0)
  

// useEffect(()=>{
//     if(lastCmpValueMini.current==0) {
//         lastCmpValueMini.current=percentage        
//     }
// },[])



useEffect(() => {
  const score =Number(userInfo?.voteStatistics?.score || 0) - Number(userInfo?.rewardStatistics?.total || 0) * 100;
  if(!userInfo?.uid){
    lastCpmMini.current = 0;
  }else if((lastCpmMini.current==0) || lastCpmMini.current==undefined){        
    lastCpmMini.current = Number(score.toFixed(3));
  }
},[userInfo?.uid])



const newScore = localStorage.getItem(`${user?.uid}_newScores`) || "0";
  useEffect(() => {
    if (newScore != "0") {
      const stopValue = 0;
      setTimeout(() => {
        let cmpVal = prevCMP.current;
        if (cmpVal > 0 && parseFloat(newScore) > parseFloat(cmpVal)) {
          claimCountFun();
          var normalValue = 100 - (parseFloat(newScore) - parseFloat(cmpVal));
          var startVal = normalValue < 0 ? 0 : normalValue ;                          
          setStartValue(parseFloat(startVal) || 0);
          // let endVal = startVal + parseFloat(newScore);
          setEndValue(100);          
          const TotalCmpValue =Number(newScore) + Number(lastCpmMini.current)
          ExtraValue.current=TotalCmpValue > 100 ? TotalCmpValue - 100 : 0;
          console.log("check box 11",startVal,endValue,newScore,cmpVal)
        // }          
        } else if (parseFloat(cmpVal) > parseFloat(newScore)) {
          const checkTotla = Number(cmpVal) + Number(newScore) 
            startVal = parseFloat(cmpVal) - parseFloat(newScore);
            setStartValue(parseFloat(startVal) || 0);
            let endVal = startVal + parseFloat(newScore);
            setEndValue(parseFloat(endVal) || endValue);
          console.log("check box 22",startVal,endValue,newScore,cmpVal)
        } else if (parseFloat(cmpVal) == 0 ||  parseFloat(cmpVal) == parseFloat(newScore)) {
          startVal = 0;
          setStartValue(parseFloat(startVal) || 0);
          let endVal = (startVal + parseFloat(newScore)) > 100 ? 100 : startVal + parseFloat(newScore);
          setEndValue(parseFloat(endVal) || endValue);
          console.log("check box 33",startVal,endValue,newScore,cmpVal)          
          ExtraValue.current=Number(newScore) > 100 ? Number(newScore) - 100 : 0;
        }        
      }, [10]);
      setTimeout(() => {        
        if(ExtraValue.current == 0){
          localStorage.setItem(`${user?.uid}_newScores`, 0);
          localStorage.setItem(
            `${user?.id}_prevScores`,
            userInfo?.voteStatistics?.score % 100
          );         
          setStartValue(false);
          setEndValue(parseFloat(userInfo?.voteStatistics?.score % 100));
          prevCMP.current = Number(percentage);
          latestCMP.current = undefined;          
          // lastCpmMini.current=Number(percentage > 100 ? Number(percentage) -100 : percentage);
          lastCpmMini.current=parseFloat(userInfo?.voteStatistics?.score % 100);
          console.log(lastCpmMini.current,percentage,"lastCpmMini.current 112233")
          setCurrentCMP(0);          
        }
      }, [10000]);
    }
  }, [percentage]);

useEffect(() => {  
  if (ExtraValue.current > 0 && animationStop==true) {    
    setTimeout(() => {            
      setStartValue(0);          
      setEndValue(Number(ExtraValue.current));      
    }, [10]);
    setTimeout(() => {
      localStorage.setItem(`${user?.uid}_newScores`, 0);
      localStorage.setItem(
        `${user?.id}_prevScores`,
        userInfo?.voteStatistics?.score % 100
      );         
      setStartValue(false);
      setEndValue(parseFloat(userInfo?.voteStatistics?.score % 100));
      prevCMP.current = Number(percentage);
      latestCMP.current = undefined;      
      // lastCpmMini.current=Number(percentage > 100 ? Number(percentage) - 100 : percentage);   
      lastCpmMini.current=parseFloat(userInfo?.voteStatistics?.score % 100);   
      setAnimationStop(false)
      ExtraValue.current=0;
      setCurrentCMP(0);      
    }, [ExtraValue.current > 100 ?10000:7000]);
  }
  },[ExtraValue,animationStop])  
  return (
    <div style={{ width: w > 767 ? "98%" : "52%" }}>
      <CircularProgressbarWithChildren
        value={100}
        strokeWidth={1}
        styles={buildStyles({
          pathColor: w > 767 ? "#fff" : "#d9d9d9",
          pathTransition: "none",
          strokeLinecap: "butt",
          trailColor: "transparent",
        })}
      >
        {startValue !== false ? (
          <AnimatedProgressProvider
            valueStart={Number(startValue)}
            valueEnd={Number(endValue)}
            // duration={localStorage.getItem(`${user?.uid}_newScores`) > 50 ? 10:7}
            duration={10}
            easingFunction={easeCubicInOut}
          >
            {(value) => {
              const roundedValue = Number(value.toFixed(3));              
              if(roundedValue ==100 && endValue == 100){                  
                  handleCmpPopupShow()
                  setStartValue(false);
              }
              return (
                <>
                  <div>
                    <CircularProgressbarWithChildren
                      value={roundedValue < 0 ? 0 : roundedValue}
                      strokeWidth={w > 767 ? 11 : 13}
                      styles={buildStyles({
                        pathColor: "#6352e8",
                        pathTransition: "none",
                        strokeLinecap: "butt",
                        trailColor: w > 767 ? "transparent" : "#160133",
                      })}
                    >
                      <span
                        style={{
                          color:"white",
                          fontSize: w > 767 ? "20px" : "16px",
                        }}
                      >
                        {(roundedValue > 100 ? 100 : roundedValue).toFixed(3)}
                        /100
                      </span>
                      <span
                        style={{
                          color:"white",
                          fontSize: w > 767 ? "20px" : "16px",
                        }}
                      >
                        CMP
                      </span>
                    </CircularProgressbarWithChildren>
                  </div>
                </>
              );
            }}
          </AnimatedProgressProvider>
        ) : (
            <div className="">
            <CircularProgressbarWithChildren
              // value={
              //   (endValue ? parseFloat(endValue) : percentage) -
              //     localStorage.getItem(`${user?.uid}_newScores`) <
              //   0
              //     ? 0
              //     : (parseFloat(endValue) ? parseFloat(endValue) : percentage) -
              //       localStorage.getItem(`${user?.uid}_newScores`)
              // }
              value={lastCpmMini.current}
              strokeWidth={w > 767 ? 11 : 13}
              styles={buildStyles({
                pathColor: "#6352e8",
                pathTransition: "none",
                strokeLinecap: "butt",
                trailColor: w > 767 ? "transparent" : "#160133",
              })}
            >
              <span
                style={{
                  color:"white",
                  fontSize: w > 767 ? "20px" : "16px",
                }}
              >
                {/* {((endValue ? parseFloat(endValue) : percentage) - currentCMP >
                100
                  ? 100
                    : (endValue ? parseFloat(endValue) : percentage) - currentCMP < 0 ? 0 : (endValue ? parseFloat(endValue) : percentage) - currentCMP
                )
                  .toFixed(3)} */}
                {Number(lastCpmMini?.current).toFixed(3)}/100
              </span>
              <span
                style={{
                  color:"white",
                  fontSize: w > 767 ? "20px" : "16px",
                }}
              >
                CMP
              </span>
            </CircularProgressbarWithChildren>
          </div>
        )}
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default CircularProgress;
