import React, { useEffect, useState } from "react";
import { useTranslation } from "../common/models/Dictionary";
import BigLogo from "../assets/svg/logoiconx2.png";
import styled from "styled-components";

const LoadingBar = styled("div")`
  
  height: 20px;
  background: transparent;
  border-radius: 10px;
  z-index: 10;
  border: 2px solid white;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
`;

const Spinner = ({ isLoaded }: any) => {

  const [lodderText, setLodderText] = useState<any>([
    "CMP stands for Coin Mining Process",
    "For each friend you invite and joins, you gain a lifetime of rewards",
    "Share your unique referral link with friends, it will step-up your game to a new level",
    "Agree to disagree? - Let the voting begin",
    "Logging in consecutive days entitles you to more benefits",
    "Losing is not an option. all we offer you is a chance to win big, or to win small",
  ]);
  
  const [selectedText, setSelectedText] = useState<any>("")
  const translate = useTranslation();
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setSelectedText(lodderText[Math.floor(Math.random() * lodderText.length)]) 
  },[])

  useEffect(() => {
    if (isLoaded) {
      setProgress(100); // Instantly complete when isLoaded is true
      return;
    } 
    const interval = setInterval(() => {
      setProgress((prev) => (prev < 99 ? prev + 2.375 : prev)); // Stops at 87.5% until isLoaded is true
    }, 100);

    return () => clearInterval(interval);
  }, [isLoaded])

  
  return (
    <React.Fragment>
      <div className="tada m-2" style={{
        width: '70%',
        textAlign: 'center',
        fontSize: '1.2rem',
      }}>
      {selectedText !=="" && <div className="loadingbar-container"
      style={{
        display: "flex",
        justifyContent:"center",
        margin: "10px 0px",
      }}
      >
        <LoadingBar
        style={{
          width: `${window.screen.width < 768 ? "100%" : "70%"}`
        }}
        >
          <div className="progressBarValue" style={{ width: `${progress}%` }}>
            {/* <div className="glowBar"></div> */}
          </div>
        </LoadingBar>
      </div>}
        {/* {translate("Wait for it...").toUpperCase()} */}
        {selectedText}
      </div>
      {/* <div className="tada m-2"> <img src={BigLogo}></img></div> */}
    </React.Fragment>
  );
};

export default Spinner;
