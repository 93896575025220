import { Buttons } from 'Components/Atoms/Button/Button';
import React, { useContext, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import coin_vet_won from "../../assets/images/coin_vet_won_empty.png";
import styled from 'styled-components';
import coinGif from "../../assets/images/coinGif.gif";
import CountUp from 'react-countup';
import AppContext from 'Contexts/AppContext';
import { VoteContext, VoteDispatchContext } from 'Contexts/VoteProvider';
import { CompletedVotesDispatchContext } from 'Contexts/CompletedVotesProvider';
import UserContext from 'Contexts/User';
import ManagersContext from 'Contexts/ManagersContext';

const Popuphead = styled.p`
  font-size: 25px;
  font-weight: 600;
  background: linear-gradient(180deg, #fff8a6 29.44%, #ffc00b 73.33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  // text-shadow: 0px 1px 3px #FFCB35;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

interface ExtraVTEProps {
  type?:any,
  GoldenVote?:any,
  setLessTimeVoteDetails: React.Dispatch<React.SetStateAction<any>>,
  setOpenPopup: React.Dispatch<React.SetStateAction<any>>;
}




const ExtraVTE : React.FC<ExtraVTEProps> = ({type, GoldenVote,setLessTimeVoteDetails,setOpenPopup})=> {
    const [modalShow, setModalShow] = React.useState(false);
    const [showGetVTE, setShowGetVTE] = React.useState(false);
    const [showCoinMove, setShowCoinMove] = React.useState(true);
    const [afterLoad, setafterLoad ] = React.useState<any>(false);
    const {setAnimateBox,setAfterVotePopup, } = useContext(AppContext);
    const { headerCpmStart,setHeaderCpmStart, } = useContext(AppContext);
    const voteDetails = useContext(VoteContext);
    const setVoteDetails = useContext(VoteDispatchContext);
    const setCompletedVotes = useContext(CompletedVotesDispatchContext);
    const { userInfo } = useContext(UserContext);
    const {TimeframesMng } = useContext(ManagersContext);

    // const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);

console.log(GoldenVote,"voteDetailsvoteDetails11221122")
    const removeVote = () => {
      let temp = {};
      setVoteDetails((prev) => {
        // console.log(Object.keys(prev?.activeVotes),"Object.keys(prev?.activeVotes)")
        Object.keys(prev?.activeVotes).map((key: string) => {
          console.log(key,"Object.keys(prev?.activeVotes)")
          if (/* prev?.activeVotes[key].expiration > new Date().getTime() &&  */GoldenVote?.voteId !== voteDetails?.activeVotes[key].voteId) {
            temp = { ...temp, [`${voteDetails?.activeVotes[key].coin}_${voteDetails?.activeVotes[key]?.timeframe?.seconds}`]: voteDetails?.activeVotes[key] }
          }
        });
        console.log(GoldenVote, prev?.activeVotes.voteId,"temptemptemp")
        return {
          ...prev,
          lessTimeVote: undefined,
          activeVotes: temp,
          openResultModal: false,        
        };
      });
      if (Object.keys(temp)?.length <= 0 && (Number(userInfo?.voteValue) + Number(userInfo?.rewardStatistics?.extraVote)) <= 0) {
        setAfterVotePopup(true);
      }
      setCompletedVotes(prev => prev.filter(value => value.voteId != GoldenVote.voteId));
      setLessTimeVoteDetails(undefined);      
    }


    const handleClose = () => {
      removeVote();    
      setHeaderCpmStart(true);            
      setAnimateBox(true);
      setOpenPopup("none")
      setTimeout(() => {
        setAnimateBox(false);        
    }, 10000 )
    };
    useEffect(() =>{
      handleShow()
    },[type])
  return (
    <div>      
      <div className=''>
        {/* reward modal 1 */}        
        <Modal
          show={modalShow}
          onHide={handleClose}
          backdrop="static"
          contentClassName={"modulebackground ForBigDiv"}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation={false}
          style={{ backgroundColor: "rgba(0,0,0,0.9)", zIndex: "2200" }}
          id="popupid"
        >
          <div className='d-flex justify-content-center GoldenVoteText'>
            <p>Golden Vote Reward</p>
          </div>
          <Modal.Body
            className="d-flex  flex-column  justify-content-between align-items-center"
            style={{
              width: `${window.screen.width > 767 ? "500px" : "100%"}`,
              height: "400px",
            }}
          >
            {showGetVTE ? (
              <>
                <Popuphead> Congrats!</Popuphead>
                {/* @ts-ignore*/}
                <div className="">
                  <p
                    style={{
                      fontSize: "24px",
                      color: "white",
                      fontWeight: "600",
                    }}
                  >
                    You've won{" "}
                    
                    {
                      // @ts-ignore
                      <CountUp end={GoldenVote?.goldenVoteReward?.quantity}
                        duration={2.75} 
                      />
                    }{" "}VTE{" "}
                    coins{" "}
                  </p>                  
                </div>

                <div className="d-flex justify-content-center ">
                  <Buttons.Primary
                    className="mx-2"
                    onClick={() => {
                      handleClose()
                    }}
                  >
                    COLLECT YOUR COINS
                  </Buttons.Primary>
                </div>
              </>
            ) : (
              <>
                  {type=="VTE"?
                    <>

                    <div className="d-flex justify-content-center align-items-center flex-column m-auto">

                    {showCoinMove && <div className="d-none justify-content-center mt-5">
                      <Buttons.Primary
                        className="mx-2 opacity-0"
                        disabled={true}
                      >
                        Reveal VTE
                      </Buttons.Primary>
                    </div>}
                    
                    {!showCoinMove && <div>
                      <img src={coinGif} alt="" width={"200px"} />
                    </div>}
                    {showCoinMove && <div className="coin_with_button">                    
                      <img src={coin_vet_won} alt="" width={"200px"}
                        onLoad={() => {
                          setafterLoad(true)
                      }}
                      />
                      {afterLoad && <Buttons.Primary
                        className="mx-2 btn-text"
                        onClick={() => {
                          setShowCoinMove(false);
                          setTimeout(() => {
                            setShowCoinMove(true);
                            setShowGetVTE(true);
                            // claimRewardSoundplay();
                          }, 5000);
                        }}
                      >
                        TAP 
                      </Buttons.Primary>}
                    </div>}

                    {showCoinMove &&
                    <div className="d-flex justify-content-center mt-4 d-none">
                      <h3 className="text-white mb-0">Reveal VTE</h3>
                      <Buttons.Primary
                        className="mx-2 d-none"
                        onClick={() => {}}
                      >
                        Reveal VTE 
                      </Buttons.Primary>
                    </div>}
                </div>
                </>
                :type=="10SEC_VOTETIME"?
                <>                
                <Popuphead> Congrats!</Popuphead>
                {/* @ts-ignore*/}
                <div className="">
                  <p
                    style={{
                      fontSize: "24px",
                      color: "white",
                      fontWeight: "600",
                      textAlign:"center",
                    }}
                  >           
                  Awesome! Your {TimeframesMng?.obj[0]?.seconds || 7}-second vote is Active for the next 7 days.                             
                  </p>                  
                </div>

                <div className="d-flex justify-content-center ">
                  <Buttons.Primary
                    className="mx-2"
                    onClick={() => {
                      handleClose()
                    }}
                  >
                    Activate now
                  </Buttons.Primary>
                </div>
              
                </>
                :type=="EXTRAVOTES"?
                <>                
                <Popuphead> Congrats!</Popuphead>
                {/* @ts-ignore*/}
                <div className="">
                  <p
                    style={{
                      fontSize: "24px",
                      color: "white",
                      fontWeight: "600",
                    }}
                  >
                    You've won{" "}
                    
                    {
                      // @ts-ignore
                      <CountUp end={GoldenVote?.goldenVoteReward?.quantity}
                        duration={2.75} 
                      />
                    }{" "}EXTRA VOTE{" "}                    
                  </p>                  
                </div>

                <div className="d-flex justify-content-center ">
                  <Buttons.Primary
                    className="mx-2"
                    onClick={() => {
                      handleClose()
                    }}
                  >
                    COLLECT YOUR COINS
                  </Buttons.Primary>
                </div>
              
                </>:
                <>
                <Popuphead> Congrats!</Popuphead>
                {/* @ts-ignore*/}
                <div className="">
                  <p
                    style={{
                      fontSize: "24px",
                      color: "white",
                      fontWeight: "600",
                    }}
                  >
                    You've won MINING PASS 
                  </p>                  
                </div>

                <div className="d-flex justify-content-center ">
                  <Buttons.Primary
                    className="mx-2"
                    onClick={() => {
                      handleClose()
                    }}
                  >
                    COLLECT YOUR MINING PASS
                  </Buttons.Primary>
                </div>
                </>
                }
              </>
            )}
          </Modal.Body>
          {/* </Modal.Footer>       */}
        </Modal>
      </div>
    </div>
  )
}

export default ExtraVTE
