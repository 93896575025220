import { useContext, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import "../Style.css";
import Button, { Buttons } from "Components/Atoms/Button/Button";
import styled from "styled-components";
import axios from "axios";
import { DESTRUCTION } from "dns";
import { useParams } from "react-router-dom";

import {
  getCurrentDate,
  getCurrentDateTime,
  getCurrentTime,
  handleValidation,
} from "common/utils/helper";
import moment from "moment";
import WaitForResponse from "./WaitForResponse";
import AppContext from "Contexts/AppContext";
type opentradetype = {
  openModal: string;
  setOpenModal: any;
  tradeCrad?: any;
  leader?: any;
};

const TradeButtons = styled.button`
  color: white;
  font-size: 12px;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  margin: 0px 10px;
`;
function BuyerPopup({
  openModal,
  setOpenModal,
  tradeCrad,
  leader,
}: opentradetype) {
  const [BuyerCardShow, setBuyerCardShow] = useState(false);
  const [BuyerDetails, setBuyerDetails] = useState(false);
  const [freeCard, setFreeCard] = useState(false);
  const {setOtherModalOpen} = useContext(AppContext);
  const paramsUrl = window.location.pathname;
  let params = useParams();
  const { type, id } = params;
  const [radiobuttonType, setRadiobuttonType] = useState<any>([
    // { lable: "Ask for free", value: "FREE" },
    {
      lable: `${paramsUrl.includes("/profile") ? "Sale" : "Buy"}`,
      value: "COIN",
    },
  ]);
  const [buyType, setBuyType] = useState<any>("COIN");
  const [selectTimer, setSelectTimer] = useState<any>("");
  const [discription, setDiscription] = useState<any>();
  const [days, setDays] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [coinVal, setCoinVal] = useState<number>();
  const [getChatData, setGetChatData] = useState<any>();
  const [isEdit, setIsEdit] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [waitRespons, setWaitRespons] = useState(false);

  const handleChange = (event: any) => {
    setErrors((prev: any) => ({ ...prev, selectedDate: "" }));    
    setSelectedDate(event.target.value);
  };

  const handleBuyerCardClose = () => {
    setOtherModalOpen(false);
    setBuyerCardShow(false);
    setOpenModal("");
    setIsEdit(false);
    setErrors({});
    setBuyerDetails(false);
    console.log("now i am close");
  };
  const handleBuyerCardShow = () => {
    setBuyerCardShow(true)
    setOtherModalOpen(true);
  };

  const handleBuyerDetailsClose = () => {
    setOtherModalOpen(false);
    setBuyerDetails(false);
    setOpenModal("");
  };

  const handleFreeCardShow = () => {
    setFreeCard(true)
    setOtherModalOpen(true);
  };

  const handleFreeClose = () => {
    setFreeCard(false);
    setOpenModal("");
  };
  const handleBuyerDetailsShow = () => {
    setBuyerDetails(true)
    setOtherModalOpen(true);
  };

  console.log(openModal, "buyercards", "yes i am calling");
  useEffect(() => {
    if (openModal == "buyercards") {
      handleBuyerCardShow();
    }
    if (openModal == "freecard") {
      console.log(openModal, "buyercards", "yes i am calling");
      handleFreeCardShow();
    }
    if (openModal == "openCardDetails") {
      let hour: any = {
        1: "Hour",
        24: "Day",
        168: "Week",
      };
      setIsEdit(true);
      setBuyType(leader?.tradeType);
      setSelectTimer(
        leader?.durationType == "CUSTOM"
          ? "Custom"
          : leader?.durationType == "LIMIT"
          ? hour[leader?.duration]
          : leader?.durationType == "NO_LIMIT"?"NO_LIMIT": ""
      );
      if (leader?.durationType == "CUSTOM") {
        // setDays(leader?.duration/24);
        let getTimeValue = getCurrentDateTime(leader?.endTimeDuration)        
        setSelectedDate(getTimeValue);        
      }
      if (leader?.tradeType != "FREE") {
        setCoinVal(leader?.coin);
      }
      if (leader?.message != "") {
        setDiscription(leader?.message);
      }
      // handleBuyerDetailsShow();
      handleBuyerCardShow();
    }
    if (openModal == "buyerDetails") {
      let hour: any = {
        1: "Hour",
        24: "Day",
        168: "Week",
      };

      setBuyType(leader?.tradeType);      
      setSelectTimer(
        leader?.durationType == "CUSTOM"
          ? "Custom"
          : leader?.durationType == "LIMIT"
          ? hour[leader?.duration]
          : leader?.durationType == "NO_LIMIT" ? "NO_LIMIT" : ""
      );

      if (leader?.durationType == "CUSTOM") {
        // setDays(leader?.duration/24);
        let getTimeValue = getCurrentDateTime(leader?.endTimeDuration)
        setSelectedDate(getTimeValue);
      }
      if (leader?.tradeType != "FREE") {
        setCoinVal(leader?.coin);
      }
      if (leader?.tradeType != "FREE") {
        setDiscription(leader?.message);
      }
      handleBuyerDetailsShow();
    }
    return () => {
      setOtherModalOpen(false);
    };
  }, []);

  console.log("leader?.tradeCardData", leader);

  const onHabdleSendRequast = async () => {
    setWaitRespons(true)
    console.log("updatetradeChat onHabdleSendRequast");
    let hour: any = {
      Hour: 1,
      Day: 24,
      Week: 168,
    };    
    const endTimeDuration: any = new Date(selectedDate).toUTCString();    
    const data = {
      transitionType: leader?.transitionType || "BID",
      tradeType: buyType,
      coin: coinVal && coinVal > 0 ? Number(coinVal) : 0,
      durationType:
        selectTimer == "Custom"
          ? "CUSTOM"
          : selectTimer == "NO_LIMIT"
          ? "NO_LIMIT"
          : "LIMIT",
      duration:
        selectTimer == "Custom"
          ? `${0}`
          : selectTimer == "NO_LIMIT"
          ? `${0}`
          : `${hour[selectTimer]}`,
      endTimeDuration: selectTimer == "Custom" ? endTimeDuration : "",
      status: "PENDING",
      message: discription,
    };
    console.log(data, "alldatapayload");
    
    await tradeCrad(
      data,
      () => {
        setWaitRespons(false);
        handleBuyerCardClose()
      },
      isEdit,      
      (message: any) => {
        console.log("ErrorMessageData yes i am calling");
        setErrorMessage(message);
        setWaitRespons(false);
      },
      leader,
      // () => handleBuyerCardClose(),
      // getChatData
    );
  };

  const handleFormSubmit = () => {
    const newErrors: any = {};
    setErrorMessage("");
    if (!buyType) {
      newErrors.buyType = "Please select a buy type.";
    }
    if (buyType !== "FREE" && (!coinVal || coinVal<1)) {
      newErrors.coinVal = "Please enter VTE value.";
    }
    if (!selectTimer) {
      newErrors.selectTimer = "Please select a timer.";
    }

    if (selectTimer === "Custom") {
      if (selectedDate == "") {
        newErrors.selectedDate = "Please select date and time";
      }
      const selectedDateTime = new Date(selectedDate);
      const currentDateTime = new Date();
      const oneHourLater = new Date(currentDateTime.getTime() + 60 * 60 * 1000);
      // @ts-ignore
      if ( selectedDateTime=="Invalid Date") {
        newErrors.selectedDate = "Please select a valid date and time.";
      }
      if (selectedDateTime < oneHourLater) {
        newErrors.selectedDate = "Selecting a past date and time is not allowed. Please choose a time at least 1 hour ahead.";
      }
    }
    if (!discription) {
      newErrors.discription = "Please select a message.";
    }
    console.log("newErrors updatetradeChat", newErrors);
    if (Object.keys(newErrors).length === 0) {
      // All validations passed, handle form submission
      onHabdleSendRequast();
      // handleBuyerCardClose();
    } else {
      setErrors(newErrors);
    }
    console.log(newErrors, buyType, coinVal, "i am calling formSubmit");
  };

  const onHandleReject = async () => {
    await axios
      .put(`/cardTrading/updateTradeStatus/${leader?.tradeCardData?.id}`, {
        status: "REJECTED",
      })
      .then((res: any) => {
        if (res.status === 200) {
          handleBuyerCardClose();
        }
        console.log("updateTradeStatus REJeCT", res);
      })
      .catch((error: any) => {
        console.log("updateTradeStatus error REJeCT", error);
        handleBuyerCardClose();
      });
  };

  const conVertDays = (hours: any, type: any, endTime?: any) => {
    if (hours == 1) {
      return "1 Hour";
    }
    if (hours == 24) {
      return "1 day";
    }
    if (hours == 168) {
      return "1 Week";
    }
    if (hours > 168) {
      const days = hours / 24;
      return `${days} days`;
    }
    if (hours == 0 && type == "NO_LIMIT") {
      return `No Limit`;
    }
    if (hours == 0 && type == "CUSTOM") {
      return moment(endTime).format("MMMM Do, YYYY h:mm A");
    }
  };

  return (
    <div>      
      <div>
        <Modal
          dialogClassName="modal-35w"
          show={BuyerCardShow}
          onHide={handleBuyerCardClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ opacity: 1, zIndex: 9999 }}
          className="borderColor"
          // animation={false}
          backdrop="static"
          contentClassName={window.screen.width <= 750 ? "w-100" : "w-50"}
        >          
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn-close "
              aria-label="Close"
              onClick={handleBuyerCardClose}
            ></button>
          </div>
          {waitRespons && (
            <>
              <WaitForResponse />
            </>
          )}
          <Modal.Body>
            <>
              <div className="d-flex justify-content-around">
                <strong>
                  {openModal != "buyerDetails" && openModal != "openCardDetails"
                    ? "Open buy offer"
                    : "Edit your offer"}
                </strong>                
              </div>
              <div className="d-flex justify-content-around flex-wrap">
                {buyType != "FREE" && (
                  <>
                    <input
                      className="SetInputSize"
                      type="text"
                      name=""
                      placeholder="Enter VTE amount"
                      value={coinVal}
                      onChange={(e: any) => {
                        const newValue = e.target.value;
                        const sanitizedValue = newValue.replace(/[^0-9]/g, "");
                        if (sanitizedValue?.length < 16) {
                          setCoinVal(sanitizedValue);
                        } 
                        // setCoinVal(sanitizedValue);
                        setErrors((prev: any) => ({ ...prev, coinVal: "" }));
                      }}
                      id=""
                    />
                    {errors?.coinVal && (
                      <p className="text-danger text-center" style={{ fontSize: "12px" }}>
                        {errors?.coinVal}
                      </p>
                    )}
                  </>
                )}
                {/* <div className="d-flex"> */}
                <select
                  className="SetInputSize"
                  aria-label="Default select example"
                  value={selectTimer}
                  onChange={(e) => {
                    setSelectTimer(e.target.value);
                    setErrors((prev: any) => ({ ...prev, selectTimer: "" }));
                  }}
                >
                  <option value="">Select time</option>
                  <option value="Hour">1 Hour</option>
                  <option value="Day">1 Day</option>
                  <option value="Week">1 Week</option>
                  <option value="NO_LIMIT">No Limit</option>
                  <option value="Custom">Custom</option>
                </select>
                {errors?.selectTimer && (
                  <p className="text-danger text-center" style={{ fontSize: "12px" }}>
                    {errors.selectTimer}
                  </p>
                )}
                {/* </div> */}
                {selectTimer == "Custom" && (
                  <>
                    <input
                      className="SetInputSize"
                      type="datetime-local"
                      id="datetime"
                      value={selectedDate}
                      onChange={handleChange}
                      min={getCurrentDateTime()}
                      // onBlur={(event)=>handleValidation(event)}
                    />
                    {errors.selectedDate && (
                      <p className="text-danger text-center" style={{ fontSize: "12px" }}>
                        {errors.selectedDate}
                      </p>
                    )}
                  </>
                )}
                <select
                  className="SetInputSize"
                  aria-label="Default select example"
                  name=""
                  id="Select Message"
                  value={discription}
                  onChange={(e) => {
                    setDiscription(e.target.value);
                    setErrors((prev: any) => ({ ...prev, discription: "" }));
                  }}
                >
                  <option value="">Select message</option>
                  <option value="Selling? I've placed a bid!">
                    Selling? I've placed a bid!
                  </option>
                  <option value="Want to sell? I've offered!">
                    Want to sell? I've offered!
                  </option>
                  <option value="Ready to trade? Check my offer!">
                    Ready to trade? Check my offer!
                  </option>
                  <option value="Interested in selling? Offer made!">
                    Interested in selling? Offer made!
                  </option>
                </select>
                {errors?.discription && (
                  <p className="text-danger text-center" style={{ fontSize: "12px" }}>
                    {errors.discription}
                  </p>
                )}
              </div>

              {errorMessage != "" && (
                <div className="text-center">
                  <p className="text-danger text-center" style={{ fontSize: "12px" }}>
                    {errorMessage}
                  </p>
                </div>
              )}
              <div className="w-100 d-flex justify-content-center mt-4">
                {openModal == "openCardDetails" ||
                openModal == "buyerDetails" ? (
                  <Buttons.Primary onClick={handleFormSubmit}>
                    {/* {buyType == "COIN" ?"Create offer": "Sent Requrst"} */}
                    Update Offer
                  </Buttons.Primary>
                ) : (
                  <Buttons.Primary onClick={handleFormSubmit}>
                    {buyType == "COIN" ? "Create offer" : "Send Request"}
                  </Buttons.Primary>
                )}
              </div>
            </>
          </Modal.Body>
        </Modal>
      </div>
      
      <div>
        <Modal
          dialogClassName="modal-35w"
          show={BuyerDetails}
          onHide={handleBuyerDetailsClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ opacity: 1, zIndex: 9999 }}
          className="borderColor"
          // animation={false}
          backdrop="static"
          contentClassName={window.screen.width <= 750 ? "w-100" : "w-50"}
        >
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn-close "
              aria-label="Close"
              onClick={handleBuyerDetailsClose}
            ></button>
          </div>
          <Modal.Body>
            <p className="text-center">
              Expiry time ={" "}
              {conVertDays(
                Number(leader?.duration),
                leader?.durationType,
                leader?.endTimeDuration
              )}
            </p>
            <p className="text-center">Coin = {leader?.coin} VTE</p>
            <p className="text-center">Message = {leader?.message}</p>
            <div className="w-100 d-flex justify-content-center mt-4">
              <TradeButtons
                style={{
                  backgroundColor: "#e05858",
                }}
                onClick={handleBuyerDetailsClose}
              >
                Cancel
              </TradeButtons>
              <TradeButtons
                style={{
                  backgroundColor: "#9095a1",
                }}
                onClick={() => {
                  setBuyerDetails(false);
                  setBuyerCardShow(true);
                  setOpenModal("buyerDetails");
                }}
              >
                Edit
              </TradeButtons>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default BuyerPopup;
