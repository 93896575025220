import { useCallback, useContext, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import "../Style.css";
import Button, { Buttons } from "Components/Atoms/Button/Button";
import styled from "styled-components";
import axios from "axios";
import { DESTRUCTION } from "dns";
import { useParams } from "react-router-dom";
// import { getSerchByUserName } from "common/utils/helper";
import { debounce } from "lodash";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import { getCurrentDateTime, getSerchByUserName } from "common/utils/helper";
import WaitForResponse from "./WaitForResponse";
import UserContext from "Contexts/User";
import AppContext from "Contexts/AppContext";
type opentradetype = {
  openModal: string;
  setOpenModal: any;
  openPopupType?:any
  tradeCrad?: any;
  leader?: any;
};

const TradeButtons = styled.button`
  color: white;
  font-size: 12px;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
`;
function CardSalePopup({
  openModal,
  setOpenModal,
  openPopupType,
  tradeCrad,
  leader,
}: opentradetype) {
  const {setOtherModalOpen} = useContext(AppContext);
  const [BuyerCardShow, setBuyerCardShow] = useState(false);
  const [BuyerDetails, setBuyerDetails] = useState(false);  
  const [freeCard, setFreeCard] = useState(false);
  const { user, userInfo } = useContext(UserContext);
  const paramsUrl = window.location.pathname;
  let params = useParams();
  const { type, id } = params;
  const [radiobuttonType, setRadiobuttonType] = useState<any>([
    { lable: "Gift the card", value: "GIFT" },
    {
      lable: `Sell`,
      value: "COIN",
    },
  ]);
  const [buyType, setBuyType] = useState<any>("GIFT");
  const [selectTimer, setSelectTimer] = useState<any>("");
  const [selectedDate, setSelectedDate] = useState('');
  const [description, setDiscription] = useState<any>();
  const [days, setDays] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [coinVal, setCoinVal] = useState<number>();
  const [getChatData, setGetChatData] = useState<any>();
  const [userNameGift, setUserNameGift] = useState<any>("");
  const [userIdGift, setUserIdGift] = useState<any>("");
  const [allUserList, setAllUserList] = useState<any>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [waitRespons, setWaitRespons] = useState(false);


  const handleBuyerCardClose = () => {
    setOtherModalOpen(false);
    setBuyerCardShow(false);
    setOpenModal("");
    setIsEdit(false);
    setErrors({});
    setBuyerDetails(false);
  };

  const handleChange = (event: any) => {
    setErrors((prev: any) => ({ ...prev, selectedDate: "" }));
    setSelectedDate(event.target.value);
  };

  const handleBuyerCardShow = () => {
    setBuyerCardShow(true)
    setOtherModalOpen(true);
  };

  const handleBuyerDetailsClose = () => {
    setBuyerDetails(false);
    setOpenModal("");
  };

  const handleFreeCardShow = () => {
    setFreeCard(true)
    setOtherModalOpen(true);
  };

  const handleFreeClose = () => {
    setFreeCard(false);
    setOpenModal("");
    setOtherModalOpen(false);
  };
  const handleBuyerDetailsShow = () => setBuyerDetails(true);
  
  useEffect(() => {
    if (openModal == "buyercards") {
      handleBuyerCardShow();
      setBuyType(openPopupType)
    }
    if (openModal == "freecard") {      
      console.log(openModal,"buyercards","yes i am calling")
      handleFreeCardShow();
    }
    if (openModal == "saleCardDetails") {
      console.log(leader,"getfulldata")
      let hour: any = {
        1: "Hour",
        24: "Day",
        168: "Week",
      };
      setIsEdit(true);
      if (leader?.tradeType) {        
        setBuyType(leader?.tradeType == "FREE" ? "GIFT" : leader?.tradeType);
      }
      if (leader?.buyerUserId != "null") {
        setUserIdGift(leader?.buyerUserId)
      }
      if (leader?.userName) {        
        setUserNameGift(leader?.userName);
      }
      if (leader?.buyerUserId == "" && leader?.userName !="") {
        HandleAfteronBuler(leader?.userName)
      }
      // setSelectTimer(
      //   leader?.durationType !== "CUSTOM"
      //     ? hour[leader?.duration]
      //     : "Custom"
      // );
      
      setSelectTimer(
        leader?.durationType == "CUSTOM" ? "Custom"
          : leader?.durationType == "LIMIT"
            ? hour[leader?.duration]
            : leader?.durationType == "NO_LIMIT" ? "NO_LIMIT" : ""
      );
      if (leader?.durationType == "CUSTOM") {        
        let getTimeValue = getCurrentDateTime(leader?.endTimeDuration)
        setSelectedDate(getTimeValue);   
      }
      if (leader?.tradeType != "FREE") {
        setCoinVal(leader?.coin);
      }
      if (leader?.message != "") {
        setDiscription(leader?.message);
      }
      // handleBuyerDetailsShow();
      handleBuyerCardShow();
    }
    return () => {
      setOtherModalOpen(false);
    };
  }, []);
  
  const onHabdleSendRequast = async () => {
    setWaitRespons(true);
    console.log("updatetradeChat onHabdleSendRequast");
    let hour: any = {
      Hour: 1,
      Day: 24,
      Week: 168,
    };
    const endTimeDuration: any = new Date(selectedDate).toUTCString();
    const data = {
      transitionType: buyType == "GIFT" ?"GIFT":"ASK",
      tradeType: buyType == "GIFT" ? "FREE" : buyType,
      coin: coinVal && coinVal > 0 ?Number(coinVal):0,
      durationType: selectTimer == "Custom" ? "CUSTOM"
        : selectTimer == "NO_LIMIT"
          ? "NO_LIMIT"
          : "LIMIT",
      duration: selectTimer == "Custom" ? `${0}` : selectTimer == "NO_LIMIT" ? `${0}` : `${hour[selectTimer]}`,
      endTimeDuration: selectTimer == "Custom" ? endTimeDuration : "",
      status: "PENDING",
      message: description,  
      userName: userNameGift || "",
      buyerUserName: userNameGift || "",
      buyerUserId: userIdGift || "",
      
    };
    await tradeCrad(
      data,      
      () => {
        handleBuyerCardClose()
        setWaitRespons(false);
      },
      isEdit,
      leader,
      (message:any) => {
        setErrorMessage(message)
        setWaitRespons(false);
      },
      
      // () => handleBuyerCardClose(),
      // getChatData
    );
  };

  
  const handleFormSubmit = () => {
    setErrorMessage("");
    const newErrors: any = {};
    if (!buyType) {
      newErrors.buyType = "Please select a buy type.";
    }
    if (buyType == "GIFT" && !userNameGift) {
      newErrors.gift = "Please enter user name";
    }
    if (buyType == "GIFT" && !userIdGift) {
      newErrors.gift = "Please enter valid name";
    }
    if (buyType == "GIFT" && userNameGift == userInfo?.userName) {
      newErrors.gift = "You can not gift yourself";
    }
    if (buyType !== "GIFT" && (!coinVal || coinVal < 1)) {
      newErrors.coinVal = "Please enter VTE value.";
    }
    if (!selectTimer) {
      newErrors.selectTimer = "Please select a timer.";
    }
    if (selectTimer === "Custom") {
      if (selectedDate == "") {
        newErrors.selectedDate = "Please select date and time";
      }
      const selectedDateTime = new Date(selectedDate);
      const currentDateTime = new Date();
      const oneHourLater = new Date(currentDateTime.getTime() + 60 * 60 * 1000);     
      // @ts-ignore
      if ( selectedDateTime=="Invalid Date") {
        newErrors.selectedDate = "Please select a valid date and time.";
      }
      if (selectedDateTime < oneHourLater) {
        newErrors.selectedDate = "Selecting a past date and time is not allowed. Please choose a time at least 1 hour ahead.";
      }
    }     
    if (buyType !== "GIFT" && !description) {
      newErrors.description = "Please select a message.";
    }
    console.log("newErrors updatetradeChat", newErrors);
    if (Object.keys(newErrors).length === 0) {
      // All validations passed, handle form submission
      onHabdleSendRequast();
      // handleBuyerCardClose();
    } else {
      setErrors(newErrors);
    }
    console.log(newErrors, buyType, coinVal, "i am calling formSubmit");
  };

  const onHandleReject = async () => {
    await axios
      .put(`/cardTrading/updateTradeStatus/${leader?.tradeCardData?.id}`, {
        status: "REJECTED",
      })
      .then((res: any) => {
        if (res.status === 200) {
          handleBuyerCardClose();
        }
        console.log("updateTradeStatus REJeCT", res);
      })
      .catch((error: any) => {
        console.log("updateTradeStatus error REJeCT", error);
        handleBuyerCardClose();
      });
  };
 

  const HandleAfteronBuler = async (Value: string) => {
    const newErrors: any = {};
    const userMatch = await getSerchByUserName(Value)
    if (!userMatch?.length) {
      newErrors.gift = "Please enter valid name";
      setUserIdGift("");
    }
    if (Value == userInfo?.userName) {
      newErrors.gift = "Please enter other name";
      setUserIdGift("");
    }
    if (!userMatch?.length) {      
      setErrors(newErrors);
    }    
    if (userMatch?.length > 0 && Value != userInfo?.userName) {
      setErrors((prev: any) => ({ ...prev, gift: "" }));
      setUserIdGift(userMatch[0]?.uid);
    }
}

  const handleAddUserName = (Value:any) => {
    setUserNameGift(Value);    
  };

  return (
    <div>
      <div>
        <Modal
          dialogClassName="modal-35w"
          show={BuyerCardShow}
          onHide={handleBuyerCardClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ opacity: 1, zIndex: 9999 }}
          className="borderColor"
          // animation={false}
          backdrop="static"
          contentClassName={window.screen.width <= 750 ? "w-100" : "w-50"}
        >
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn-close "
              aria-label="Close"
              onClick={handleBuyerCardClose}
            ></button>
          </div>
          {waitRespons && (
            <>
              <WaitForResponse/>
            </>            
          )}
          <Modal.Body>
            <>
              <div className="d-flex justify-content-around">
                {buyType == "GIFT" ? <strong className="text-center">Gift the card</strong> : openModal !== "saleCardDetails" ? <strong className="text-center">Open sell offer</strong> : <strong className="text-center">Edit sell offer</strong> }                                
              </div>
              <div className="d-flex justify-content-around flex-wrap">

                {buyType == "GIFT" && (
                  <>
                    <input
                      className="SetInputSize"
                      type="text"
                      name=""
                      placeholder="Type your friends username"
                      value={userNameGift}
                      onChange={(e: any) => {                        
                        handleAddUserName(e.target.value)
                        if (e.target.value.length > 4 && e.target.value.length < 16) {
                          HandleAfteronBuler(e.target.value);
                        }
                        setErrors((prev: any) => ({ ...prev, gift: "" }));                        
                      }}
                      // onBlur={(e) => {
                      //   if(e.target.value !="")
                      //   HandleAfteronBuler(e.target.value);
                      // }}
                      id=""
                    />                    
                    {errors?.gift && (
                      <p className="text-danger" style={{ fontSize: "12px" }}>
                        {errors?.gift}
                      </p>
                    )}                    
                  </>                  
                )}

                {buyType != "GIFT" && (
                  <>
                    <input
                      className="SetInputSize"
                      type="text"
                      name=""
                      placeholder="Enter VTE amount"
                      value={coinVal}
                      onChange={(e: any) => {
                        const newValue = e.target.value;
                        const sanitizedValue = newValue.replace(/[^0-9]/g, ""); 
                        console.log(sanitizedValue?.length,"sanitizedValue?.length")
                        if (sanitizedValue?.length < 16) {                          
                          setCoinVal(sanitizedValue);                                                    
                        } 

                        setErrors((prev: any) => ({ ...prev, coinVal: "" }));
                      }}
                      id=""
                    />
                    {errors?.coinVal && (
                      <p className="text-danger" style={{ fontSize: "12px" }}>
                        {errors?.coinVal}
                      </p>
                    )}
                  </>
                )}
                {/* <div className="d-flex"> */}
                <select
                  className="SetInputSize"
                  aria-label="Default select example"
                  value={selectTimer}
                  onChange={(e) => {
                    setSelectTimer(e.target.value);
                    setErrors((prev: any) => ({ ...prev, selectTimer: "" }));
                  }}
                >
                  <option value="">Select time</option>
                  <option value="Hour">1 Hour</option>
                  <option value="Day">1 Day</option>
                  <option value="Week">1 Week</option>
                  <option value="NO_LIMIT">No Limit</option>
                  <option value="Custom">Custom</option>
                </select>
                {errors?.selectTimer && (
                  <p className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.selectTimer}
                  </p>
                )}
                {/* </div> */}
                {selectTimer == "Custom" && (
                  <>
                    <input
                      className="SetInputSize"
                      type="datetime-local"
                      id="datetime"
                      value={selectedDate}
                      onChange={handleChange}
                      min={getCurrentDateTime()}
                    // onBlur={(event)=>handleValidation(event)}
                    />
                    {errors.selectedDate && (
                      <p className="text-danger" style={{ fontSize: "12px" }}>
                        {errors.selectedDate}
                      </p>
                    )}
                  </>
                )}                
                {buyType == "COIN" &&<>                
                <select
                  className="SetInputSize"
                  name=""
                  id=""
                  value={description}
                  onChange={(e) => {
                    setDiscription(e.target.value);
                    setErrors((prev: any) => ({ ...prev, description: "" }));
                  }}
                >
                  <option value="">Select message</option>
                  <option value="Let's make this card shine in your collection!">Let's make this card shine in your collection!</option>
                  <option value="Time to let this gem find its new home!">Time to let this gem find its new home!</option>
                  <option value="Ready to add some sparkle to your deck?">Ready to add some sparkle to your deck?</option>
                  <option value="Trade your way to victory with this card!">Trade your way to victory with this card!</option>
                </select>
                {errors?.description && (
                  <p className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.description}
                  </p>
                  )}

                </>}
                {errorMessage !="" && (
                  <p className="text-danger text-center" style={{ fontSize: "12px" }}>
                      {errorMessage}
                  </p>
                  )}
                
              </div>
              <div className="w-100 d-flex justify-content-center mt-4">
                {openModal == "saleCardDetails" ? <Buttons.Primary onClick={handleFormSubmit}>
                  {buyType == "COIN" ? "Update offer" : "Update gift"}                  
                </Buttons.Primary>:
                <Buttons.Primary onClick={handleFormSubmit}>
                    {buyType == "COIN" ? "Create offer" : "Send gift"}
                </Buttons.Primary>}
              </div>
            </>
          </Modal.Body>
        </Modal>
      </div>

      <div>
        <Modal
          dialogClassName="modal-35w"
          show={freeCard}
          onHide={handleFreeClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ opacity: 1, zIndex: 9999 }}
          className="borderColor"
          // animation={false}
          backdrop="static"
          contentClassName={window.screen.width <= 750 ? "w-100" : "w-50"}
        >
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn-close "
              aria-label="Close"
              onClick={handleFreeClose}
            ></button>
          </div>
          <Modal.Body>
            <div className="w-100 mb-4 text-center">
              <strong className="">Free card request</strong>
            </div>
            <p className="text-center">Pls give me this card</p>
            <div className="w-100 d-flex justify-content-around mt-4">
              <TradeButtons
                style={{
                  backgroundColor: "#6458e5",
                }}
                onClick={() => {
                  setFreeCard(false);
                  setBuyerDetails(true);
                  setOpenModal("askforcard");
                }}
              >
                Ask for VTE
              </TradeButtons>
              <TradeButtons
                style={{
                  backgroundColor: "#6458e5",
                }}
                onClick={() => {
                  // setBuyerDetails(false);
                  // setBuyerCardShow(true);
                  // setOpenModal("buyerDetails");
                }}
              >
                Approve
              </TradeButtons>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default CardSalePopup;
